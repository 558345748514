<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <validation-observer
          ref="observer"
          v-slot="{ invalid }"
          tag="form"
        >
          <v-form>
            <v-card class="elevation-12">
              <v-toolbar v-if="showAction" :color="color" dark flat>
                <v-toolbar-title>Ievadiet informāciju</v-toolbar-title>
                <v-spacer/>
              </v-toolbar>
              <v-card-text v-if="showAction">
                <validation-provider v-slot="{ errors }" name="email" rules="required|email|min:6|max:36">
                  <v-text-field
                    v-model="form.email"
                    name="email"
                    :error-messages="errors"
                    prepend-icon="fa-user"
                    type="email"
                    required
                    autofocus
                  />
                </validation-provider>
                <validation-provider v-slot="{ errors }" name="password" rules="required|min:6|max:18">
                  <v-text-field
                    v-model="form.password"
                    :counter="0"
                    name="password"
                    :error-messages="errors"
                    prepend-icon="fa-key"
                    type="password"
                    required
                  />
                </validation-provider>
              </v-card-text>
              <AlertResponse :response="alert"/>
              <v-card-actions class="justify-center" v-if="showAction">
                <v-btn :loading="loading" :disabled="invalid" @click="submit" :color="color">Autorizēties</v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AlertResponse from '@/components/AlertResponse'
import { required, email, max, min } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('required', {
  ...required,
  message: 'The {_field_} can not be empty.'
})

extend('max', {
  ...max,
  message: 'The {_field_} may not be greater than {length} characters.'
})

extend('min', {
  ...min,
  message: 'The {_field_} must be at least {length} characters.'
})

extend('email', {
  ...email,
  message: 'The {_field_} must be a valid email address.'
})

export default {
  name: 'Login',
  components: {
    AlertResponse,
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    alert: null,
    loading: false,
    showAction: true,
    color: 'primary',
    form: {
      email: '',
      password: ''
    }
  }),
  methods: {
    checkLogin () {
      //
    },
    async submit () {
      const isValid = await this.$refs.observer.validate()

      if (!isValid) {
        // TODO: return not valid to log server
      } else {
        // this.loading = true

        const payload = {
          email: this.form.email,
          password: this.form.password
        }

        const response = await this.$store.dispatch('login', payload)
        this.loading = false

        if (response.data.error) {
          this.alert = JSON.parse(response.data.error)
        } else {
          this.showAction = false
          this.alert = {
            success: 'Success'
          }
          setTimeout(() => this.$router.push({ name: 'dashboard' }), 2000)
        }

        this.form.email = ''
        this.form.password = ''
        this.$refs.observer.reset()
      }
    }
  }
}
</script>

<style scoped>

</style>
